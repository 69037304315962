//*****************************************************
//*   NAV
//*****************************************************
@use '../../scss/abstracts/mixins';
@use '../../scss/abstracts/variables';
.nav{
    // border: 2px solid red;
    height: 10vh;
    padding: 2rem;
    &__list{
        text-align: center;
        list-style-type: none;
    }
    &__item{
        display: inline;
        padding: 1rem;
    }
    &__link{
        text-transform: uppercase;
        font-size: 1rem;
        text-decoration: none;
        font-family: variables.$font-family;
        font-weight: 500;
        @include mixins.soft(.5s);
        &:hover{
            color: rgb(223, 109, 33);
        }
        &--active{
            color: variables.$secondary-color;
        }
    }
}