//*****************************************************
//*   Variables
//*****************************************************


//*Color

$primary-color:#272727;
$secondary-color:#ff652f;
$text-color:#0c0c0c;


//*font

$font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

//*path

$pathName1: "../../assets/img/model-1.jpg";
$pathName2: "../../assets/img/project-1.jpg";
$pathName3: "../../assets/img/project-2.jpg";
$pathName4: "../../assets/img/project-3.jpg";
$pathName5: "../../assets/img/project-4.jpg";
$pathName6: "../../assets/img/project-5.jpg";
$pathName7: "../../assets/img/avatar2.jpeg";