//*****************************************************
//*   MIXINS
//*****************************************************


@use './variables';

@mixin soft($sec) {
    transition: all $sec ;
}

@mixin bgImg($height, $path) {
    height: $height;
    width: 100%;
    background: linear-gradient(
    to right,
    rgba(variables.$primary-color, 0.9),
    rgba(variables.$primary-color, 0.3),
    ),
    url($path);
    background-position: center;
    background-size: cover;
}


@mixin media-xsm {
    @media screen and (min-width: 0px) {
      @content;
    }
  }
  
  @mixin media-sm {
    @media screen and (min-width: 576px) {
      @content;
    }
  }
  
  @mixin media-md {
    @media screen and (min-width: 768px) {
      @content;
    }
  }
  
  @mixin media-lg {
    @media screen and (min-width: 1024px) {
      @content;
    }
  }
  
  @mixin media-xl {
    @media screen and (min-width: 1600px) {
      @content;
    }
  }